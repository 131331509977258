const {datalayer} = window.thaliaShell;

class NewsletterAnmeldung extends HTMLElement {

    connectedCallback() {
        this.newsletterForm = this.querySelector("[name=newsletterAnmeldeformular]");
        this.rechtlicheHinweiseLink = this.querySelector(".rechtliche-hinweise-link");
        this.emailField = this.querySelector("[name=email]");
        this.newsletterForm.addEventListener("submit", event => this.handleSubmit(event));
        this.newsletterLinkMeinKonto = document.querySelector('a[href="#ocsNewsletterContainer"]');
        this.dialogRechtlicheHinweise = this.querySelector('.rechtliche-hinweise');

        this.newsletterAnmeldungErrorMessage = undefined;

        this.addRechtlicheHinweiseLinkEventListener()
        this.addMeinKontoEventListener();
        this.setEmailFromLocalStorage();
        this.urlEnthaeltNewsletterAnker();

        datalayer.submitObject.register((target) => this.submitProvider(target));
    }

    async handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(this.newsletterForm);
        const config = {
            method: 'POST',
            credentials: 'same-origin',
            body: formData
        };
        try {
            const response = await fetch("/api/newsletter/anmeldung", config);
            const json = await response.json();
            this.verarbeiteResponse(json);
        } catch (error) {
            this.showErrorMessage();
            throw error;
        }
    }

    addRechtlicheHinweiseLinkEventListener() {
        this.rechtlicheHinweiseLink.addEventListener("click",
            () => this.dialogRechtlicheHinweise.showModal());
    }

    addMeinKontoEventListener() {
        if (this.newsletterLinkMeinKonto) {
            this.newsletterLinkMeinKonto.addEventListener("click", event => this.focusNewsletterAnmeldung(event));
        }
    }

    focusNewsletterAnmeldung(event) {
        event.preventDefault();
        this.emailField.focus();
    }

    urlEnthaeltNewsletterAnker() {
        if (location.hash === "#ocsNewsletterContainer") {
            this.emailField.focus();
        }
    }

    setEmailFromLocalStorage() {
        if (localStorage["kim.login.loginUsername"] && this.emailField.value === "") {
            this.emailField.value = localStorage["kim.login.loginUsername"];
        }
    }

    verarbeiteResponse(data) {
        this.newsletterAnmeldungErrorMessage = undefined;
        if (data.anmeldungErfolgreich) {
            this.showSuccessMessage(data.newsletterMessage);
        } else {
            this.showErrorMessage(data.newsletterMessage);
            this.newsletterAnmeldungErrorMessage = data.newsletterMessage;
        }
        try {
            datalayer.triggerEvent(datalayer.EVENTS.FORM_SUBMIT, this);
        } catch (e) {
        }
    }

    showErrorMessage(newsletterMessage = undefined) {
        const successContainer = this.querySelector('.success-notification-container');
        const errorContainer = this.querySelector('.warning-notification-container');

        successContainer.classList.add('hidden');

        if (newsletterMessage) {
            errorContainer.querySelector('p').innerHTML = newsletterMessage;
        }
        errorContainer.classList.remove('hidden');
    }

    showSuccessMessage(newsletterMessage) {
        const successContainer = this.querySelector('.success-notification-container');
        const errorContainer = this.querySelector('.warning-notification-container');

        errorContainer.classList.add('hidden');

        successContainer.querySelector('p').innerHTML = newsletterMessage;

        successContainer.classList.remove('hidden');
    }

    submitProvider(target) {
        return new Promise((resolve, reject) => {
            try {
                let data = {};
                if (this.newsletterAnmeldungErrorMessage) {
                    data.errorFields = [this.newsletterAnmeldungErrorMessage];
                }
                resolve(data);
            } catch (error) {
                reject(
                    new Error(
                        `Cannot get data from ${target.outerHTML} \nCaused by: ${error}`
                    )
                );
            }
        });
    }

}

customElements.define("newsletter-anmelden", NewsletterAnmeldung);
